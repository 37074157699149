<template>
  <div >
  </div>
</template>

<script>

// import  PrintSys from '@/components/Print/index'
export default {
  name: "test",
  components:{
  },
  data(){
    return {
      'socket':null,
    }
  },
  mounted() {
    //消息回调通知



  },
  methods:{

  }
}
</script>

<style scoped>

</style>
